<template>
  <div>
    <b-container
      :class="{
        'text-center': !numero,
        'd-flex text-left': numero,
      }"
      class="d-md-none mb-3 align-items-end mt-3"
    >
      <div class="numero mb-2" v-if="numero">
        <strong>{{ numero }}</strong>
      </div>
      <h1 class="text-uppercase mb-0 font-weight-bold">
        {{ title }}
      </h1>
      <h5 v-if="subtitle">
        {{ subtitle }}
      </h5>
    </b-container>
    <b-container class="d-flex px-0">
      <div class="numero align-self-end mb-0 d-none d-md-block" v-if="numero">
        <strong>{{ numero }}</strong>
      </div>
      <h2 class="text-rotate text-left mb-0 text-uppercase d-none d-md-block title">
        {{ title }}
      </h2>
      <h5 class="text-rotate text-left mb-0 d-none d-md-block subtitle" v-if="subtitle">
        {{ subtitle }}
      </h5>
      <div class="embed-responsive embed-responsive-16by9 bg-dark" v-if="video">
        <iframe class="embed-responsive-item" :src="video + '?enablejsapi=1'" allowfullscreen>
        </iframe>
      </div>
      <div v-else class="aside aside-169">
        <CardsMansory :salles="tiles" />
      </div>
    </b-container>
    <b-container class="d-flex px-0">
      <b-progress v-if="progress !== undefined" :value="progress" style="height: .8rem;" class="my-3 w-100 d-md-none"></b-progress>
    </b-container>
    <b-row
      align-h="center"
      no-gutters
      class="mt-4 aligned-btns"
      :class="{
        'pl-4rem': !numero,
        'pl-6rem': numero,
      }"
    >
      <slot></slot>
    </b-row>
  </div>
</template>

<script>
import CardsMansory from "../components/CardsMansory.vue";

export default {
  components: {
    CardsMansory,
  },
  props: {
    numero: {
      type: Number,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    video: {
      type: String,
    },
    tiles: {
      type: Array,
    },
    progress: {
      type: Number
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 2.4rem;
  line-height: 1;
}
.subtitle {
  width: 2rem;
  line-height: 1;
}

.pl-4rem {
  padding-left: 4rem;
}

.pl-6rem {
  padding-left: 6.5rem;
}

.numero {
  color: rgba(255, 255, 255, 0.5);
  font-size: 5.5rem;
  line-height: 0.7;
  font-family: "Poppins";
  text-align: right;
  width: 4rem;
  margin-right: 0.5rem;

  strong {
    display: inline-block;
    width: 4rem;
  }
}

.text-rotate {
  writing-mode: vertical-rl;
  transform: scaleX(-1) scaleY(-1);
  font-weight: 700;
  margin: 0 0 1.5rem 0;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.15 202.98">
    <g id="fond">
      <rect class="cls-1" x="2.44" y="2.59" width="509.14" height="198" />
      <rect class="cls-1" x="46.25" y="50.84" width="418.18" height="106.49" />
      <polygon
        class="cls-1"
        points="46.25 50.84 2.44 2.59 2.44 200.59 46.25 157.33 46.25 50.84"
      />
      <polygon
        class="cls-1"
        points="511.58 2.59 464.44 50.84 464.44 157.33 511.58 200.59 511.58 2.59"
      />
      <polygon
        class="cls-1"
        points="2.44 200.59 46.25 157.33 464.44 157.33 511.58 200.59 2.44 200.59"
      />
      <polygon
        class="cls-1"
        points="2.44 2.59 46.25 50.84 464.44 50.84 511.58 2.59 2.44 2.59"
      />
      <rect class="cls-1" x="309.43" y="51.11" width="155.15" height="106.49" />
    </g>
    <g
      id="as_intech"
      :class="{ active: activeZone == 'as_intech' }"
      @mouseover="$emit('update', 'as_intech', $event)"
      @click="$emit('click', 'as_intech', $event)"
      @touchstart="$emit('click', 'as_intech', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <polygon
        class="cls-2"
        points="2.44 200.59 46.25 157.33 464.44 157.33 511.58 200.59 2.44 200.59"
      />
      <text class="cls-3" transform="translate(90 186.87)">
        Architecture des Systèmes d'Information
      </text>
    </g>
    <g
      id="vie"
      :class="{ active: activeZone == 'vie' }"
      @mouseover="$emit('update', 'vie', $event)"
      @click="$emit('click', 'vie', $event)"
      @touchstart="$emit('click', 'vie', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <polygon
        class="cls-5"
        points="511.58 2.59 464.44 50.84 464.44 157.33 511.58 200.59 511.58 2.59"
      />
      <text class="cls-3" transform="translate(483.37 47.57) rotate(90)">
        Vie Etudiante
      </text>
    </g>
    <g
      id="admission"
      :class="{ active: activeZone == 'admission' }"
      @mouseover="$emit('update', 'admission', $event)"
      @click="$emit('click', 'admission', $event)"
      @touchstart="$emit('click', 'admission', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <rect class="cls-6" x="309.43" y="51.11" width="155.15" height="106.49" />
      <text class="cls-3" transform="translate(339.49 108.31)">Admissions</text>
    </g>
    <g
      id="ecole"
      :class="{ active: activeZone == 'ecole' }"
      @mouseover="$emit('update', 'ecole', $event)"
      @click="$emit('click', 'ecole', $event)"
      @touchstart="$emit('click', 'ecole', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <rect class="cls-5" x="46.25" y="50.84" width="263.33" height="106.49" />
      <text class="cls-3" transform="translate(126.1 104.04)">
        Présentation
        <tspan x="-20.72" y="18">de l’école INTECH</tspan>
      </text>
    </g>
    <g
      id="entreprise"
      :class="{ active: activeZone == 'entreprise' }"
      @mouseover="$emit('update', 'entreprise', $event)"
      @click="$emit('click', 'entreprise', $event)"
      @touchstart="$emit('click', 'entreprise', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <polygon
        class="cls-6"
        points="46.25 50.84 2.44 2.59 2.44 200.59 46.25 157.33 46.25 50.84"
      />
      <text class="cls-3" transform="translate(26.35 142.75) rotate(-90)">
        Diplômés
      </text>
    </g>
    <g
      id="pedagogie"
      :class="{ active: activeZone == 'pedagogie' }"
      @mouseover="$emit('update', 'pedagogie', $event)"
      @click="$emit('click', 'pedagogie', $event)"
      @touchstart="$emit('click', 'pedagogie', $event)"
      @mouseleave="$emit('update', null, $event)"
    >
      <polygon
        class="cls-2"
        points="2.44 2.59 46.25 50.84 464.44 50.84 511.58 2.59 2.44 2.59"
      />
      <text class="cls-3" transform="translate(180 30.63)">
        Ingénierie Logiciel
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    activeZone: String,
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #b4b4b4;
}
.cls-1,
.cls-2,
.cls-5,
.cls-6 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-2 {
  fill: #2f4049;
}
.cls-3 {
  font-size: 18px;
  fill: #fff;
  font-family: Roboto-Bold, Roboto, sans-serif;
  font-weight: 700;
}
.cls-4 {
  letter-spacing: -0.01em;
}
.cls-5 {
  fill: #75b658;
}
.cls-6 {
  fill: #f49825;
}

svg > g:not(:first-child) > *:not(text) {
  opacity: 0;
}

svg > g.active > * {
  opacity: 1 !important;
}

svg > g:not(#fond):hover > * {
  opacity: 1;
  cursor: pointer;
}
</style>
<template>
  <b-container fluid class="text-center">
    <b-row class="py-2 py-md-5 pr-md-5" align-h="around">
      <b-col
        cols="12"
        md="10"
        xl="8"
        class="px-md-5 align-self-center text-left"
      >
        <ContentBox
          title="Introduction"
          subtitle="Petit tour d'horizon du dispositif"
          video="https://www.youtube.com/embed/tbBUSeH_e3g"
        >
          <b-btn
            variant="secondary"
            class="text-uppercase mx-3 my-1 text-white font-weight-bold"
            size="lg"
            @click="$emit('typeNav', 'guide')"
            >Je me laisse guider</b-btn
          >
          <strong class="d-block w-100 text-center my-1 d-sm-none">OU</strong>
          <b-btn
            variant="primary"
            class="text-uppercase mx-3 my-1 font-weight-bold"
            size="lg"
            @click="$emit('typeNav', 'libre')"
            >Je navigue en autonomie</b-btn
          >
        </ContentBox>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContentBox from "../components/ContentBox.vue";

export default {
  components: {
    ContentBox,
  },

  mounted() {
    this.$gtag.pageview({
      page_title: "Introduction",
      page_path: '/' + window.location.hash
    });
  },
};
</script>

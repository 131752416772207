<template>
  <b-container fluid class="pb-3">
    <b-row
      align-content="center"
      align-h="center"
      v-if="currentLive"
      class="fixed-top"
    >
      <b-col class="flashinfo px-1">
        <a
          href="https://www.youtube.com/embed/bn5NRpw5XoA"
          class="d-inline-flex align-items-center m-0"
          target="_blank"
          >[<b-icon
            icon="circle-fill"
            animation="fade"
            font-scale="0.8"
            variant="danger"
            class="mr-1"/>
          EN LIVE] {{ format(currentLive.debut, "H[H]mm") }} -
          {{ currentLive.titre
          }}<font-awesome-icon :icon="['fas', 'angle-right']" fixed-width
        /></a>
      </b-col>
    </b-row>
    <b-row
      align-content="center"
      align-h="center"
      :class="{ 'pt-md-5 pt-4': currentLive }"
    >
      <b-col cols="12" lg class="d-flex">
        <div class="mt-md-4 my-lg-auto ml-md-3 d-none d-lg-block">
          <h1 class="font-weight-bold">
            Journée Portes Ouvertes
          </h1>
          <!-- <router-link to="/">
            <font-awesome-icon :icon="['fas', 'angle-left']" fixed-width />
            Retourner à la page d'accueil</router-link
          > -->
        </div>
      </b-col>
      <b-col cols="12" lg="4" class="mr-auto">
        <b-row no-gutters align-h="between" align-v="center">
          <b-col cols="2" class="d-lg-none">
            <b-btn variant="link" class="text-white" @click="$emit('back')">
              <font-awesome-icon
                :icon="['fas', 'arrow-left']"
                fixed-width
                size="2x"
              />
            </b-btn>
          </b-col>
          <b-col cols="8" lg="12">
            <router-link to="/"
              ><img
                :src="logo"
                :alt="campus.name"
                class="d-block mx-auto logo img-fluid"
            /></router-link>
          </b-col>
          <b-col cols="2" class="text-right d-lg-none">
            <b-btn
              variant="link"
              class="text-white d-md-none"
              @click="openMenu"
              v-if="menu"
            >
              <font-awesome-icon
                :icon="['fas', 'bars']"
                fixed-width
                size="2x"
              />
            </b-btn>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg class="d-flex text-center text-lg-left">
        <div class="mt-4 mx-auto ml-lg-auto mr-lg-3">
          <h5 class="font-weight-bold mb-1">Classements 2022</h5>
          <ul class="list-unstyled mb-0">
            <!-- <li class="text-left badge badge-dark w-100 mt-1 text-white text-wrap">
              1ère école d’ingénieurs privée du Grand Ouest <small>d’après les
              classements de l’Etudiants et de l’Usine Nouvelle</small>
            </li> -->
            <li class="text-left badge badge-dark w-100 mt-1 text-white text-wrap">
              2ème école d’ingénieurs privée et meilleure progression de l’année
              <small>d’après le classement de l’Etudiant</small>
            </li>
            <li class="text-left badge badge-dark w-100 mt-1 text-white text-wrap">
              3ème école d’ingénieurs privée <small>d’après le classement de l’Usine Nouvelle</small>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";

export default {
  props: {
    menu: Boolean,
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return "esiea";
    },
    campus() {
      return this.db[this.slug];
    },
    logo() {
      return require("@/assets/logo-" + this.slug + ".svg");
    },
    currentLive() {
      return this.campus.live.find((e) => {
        let inMinutes = moment.duration(moment(e.debut).diff()).asMinutes();
        return moment(e.fin).diff() > 0 && inMinutes < 10;
      });
    },
  },

  methods: {
    format(date, format) {
      return moment(date).format(format);
    },
    openMenu() {
      this.$gtag.event("navguide_menu_open", {
        event_category: "engagement",
      });
      this.$emit("menu");
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-size: 95% !important;
}
</style>

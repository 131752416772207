<template>
  <div>
    <b-modal
      id="esieabot"
      :modal-class="slug"
      v-model="show"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
      @close="onClose"
    >
      <template #modal-title v-if="!done">Journée Portes Ouvertes</template>
      <div v-if="!done">
        <b-row align-h="center" class="esieabot-content">
          <b-col cols="12">
            <h5 class="text-center mb-3">
              <p>
                Aujourd'hui, de 10h à 17h, notre campus d’Ivry-sur-Seine, vous ouvre ses
                portes.
              </p>
              <p>
                Découvrez le campus et la vie étudiante, rencontrez notre équipe
                pédagogique, participez à des ateliers gratuits (espace game,
                code Java, My SQL, esieabot) et tenter de gagner notre Esieabot.
              </p>
              <p>
                Rendez-vous rue Edouard Vasseur, 94200 Ivry-sur-Seine (Métro ligne 7).
              </p>
              <p>À très vite !</p>
              <br />
              <p>Pour visiter notre Campus de Laval, rendez-vous le 29 janvier 2022</p>
            </h5>
          </b-col>

          <b-row>
            <b-col class="text-center">
              <b-button
                class="
                  text-uppercase text-white
                  font-weight-bold
                  mt-4
                  px-5
                  text-center
                "
                variant="secondary"
                size="lg"
                href="https://www.esiea.fr/les-evenements-esiea/"
                target="_blank"
                >Je préfère m'inscrire pour visiter le campus ></b-button
              >
            </b-col>
          </b-row>
        </b-row>
      </div>
    </b-modal>
    <b-btn variant="primary" v-if="showBtn" @click="go()" class="btn-esieabot">Visiter le campus</b-btn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    wait: Boolean,
    default: () => false,
  },

  data() {
    return {
      show: false,
      form: {
        email: "",
        prenom: "",
        nom: "",
        telephone: "",
        checked: [],
      },
      loading: false,
      done: false,
      blocked: false,
    };
  },

  computed: {
    valid() {
      return (
        this.validateEmail(this.form.email) &&
        this.form.prenom &&
        this.form.nom &&
        this.form.checked.length &&
        this.validatePhone(this.form.telephone)
      );
    },
    slug() {
      return "esiea";
    },
    showBtn() {
      return (
        !sessionStorage.getItem("esieabot") &&
        !this.show &&
        !this.done &&
        sessionStorage.getItem("esieabot-shown")
      );
    },
  },

  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      await axios.post(process.env.VUE_APP_FORM_URL, {
        ...this.form,
        ecole: this.slug,
      });
      this.done = true;
      sessionStorage.setItem("esieabot", "true");

      this.$gtag.event("form_bot_submit", {
        event_label: "Inscris au concours",
        event_category: "form_bot",
      });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhone(phone) {
      const re = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g;
      return re.test(String(phone).toLowerCase());
    },
    go() {
      if (this.blocked || this.wait) {
        setTimeout(() => {
          this.go();
        }, 5000);
      } else {
        this.show = true;
        sessionStorage.setItem("esieabot-shown", "true");

        this.$gtag.event("form_bot_open", {
          event_label: "Formulaire ESIEA BOT",
          event_category: "form_bot",
          non_interaction: true,
        });
      }
    },
    onClose() {
      this.$gtag.event("form_bot_close", {
        event_label: "Fermeture de la modale",
        event_category: "form_bot",
      });
    },
  },

  mounted() {
    if (!sessionStorage.getItem("esieabot-shown")) {
      this.go();
    }

    this.$root.$on("bv::modal::shown", (e, modalId) => {
      this.blocked = modalId;
    });

    this.$root.$on("bv::modal::hidden", (e, modalId) => {
      this.blocked = this.blocked !== modalId;
    });
  },
};
</script>

<style lang="scss">
label {
  margin-bottom: 0.2rem;
}

.form-control {
  border: none;
}

.footer-info {
  text-align: justify;
  font-size: 0.75em;
  line-height: 1.1;
}

.esieabot-img {
  position: absolute;
  bottom: 3rem;
  right: 10%;
  z-index: 1;
}

.esieabot-content {
  position: relative;
  z-index: 2;
}
</style>

<template>
  <b-container fluid class="text-center">
    <b-row
      class="h-100 align-content-stretch py-2 py-md-4 pr-md-5"
      align-h="around"
    >
      <b-col
        cols="12"
        md="6"
        xl="5"
        v-if="db.esiea"
        class="px-md-5 align-self-center text-center"
      >
        <b-btn
          variant="link"
          class="text-uppercase mb-3"
          size="sm"
          @click="$emit('change', 'guide')"
          >Je préfère être guidé</b-btn
        >
        <EsieaPlan
          class="img-fluid mx-auto my-auto"
          v-if="slug === 'esiea'"
          v-on:update="$emit('update', $event)"
          v-on:click="go"
          :activeZone="selectedZone"
        />
        <IntechPlan
          class="img-fluid mx-auto my-auto"
          v-if="slug === 'intech'"
          v-on:update="$emit('update', $event)"
          v-on:click="go"
          :activeZone="selectedZone"
        />
        <p class="font-italic mt-3">
          <b>Clique</b> sur une zone pour la sélectionner et accéder à son
          contenu
        </p>
      </b-col>
      <b-col cols="12" md="6" xl="7" class="text-left aside mt-5 mt-md-0">
        <div v-if="!zoneData" class="d-flex">
          <div class="welcome my-auto">
            <h2 class="mb-3 font-weight-bold">
              Nous sommes ravis de vous rencontrer !
            </h2>
            <p>
              Nous avons mis en place ce dispositif éphémère pour favoriser au
              maximum les échanges et répondre à vos questions !
            </p>
            <p>
              Vous pouvez parcourir ci-contre les différentes thématiques
              abordées pour cet évènement. Chacune d’entre elles vous proposera
              un contenu dédié : présentation vidéo de la thématique et espaces
              d’échanges avec l’équipe pédagogique et nos étudiants.
            </p>

            <p>À tout de suite !</p>
          </div>
        </div>
        <div v-else>
          <h2 class="mb-3 font-weight-bold">{{ zoneData.nom }}</h2>
          <CardsMansory :salles="zoneData.salles" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EsieaPlan from "@/components/EsieaPlan.vue";
import IntechPlan from "../components/IntechPlan.vue";
import CardsMansory from "../components/CardsMansory.vue";

export default {
  components: {
    EsieaPlan,
    IntechPlan,
    CardsMansory,
  },

  props: {
    zoneData: Object,
    selectedZone: String,
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return 'esiea';
    },
  },

  methods: {
    go(code) {
      this.$emit('click', code);

      this.$gtag.event("zone_selected", {
        event_category: "engagement",
        event_label: code,
        value: 2
      });
    }
  },

  mounted() {
    this.$gtag.pageview({
      page_title: 'Navigation en autonomie',
      page_path: '/' + window.location.hash
    });
  },
};
</script>
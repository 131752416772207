<template>
  <div
    id="app"
    :class="{ jpo: typeNav === 'libre', [slug]: true }"
    v-if="campus"
  >
    <CampusHeader
      @back="back"
      :menu="typeNav === 'guide'"
      @menu="menuToggle = true"
    />
    <NavLibre
      v-if="typeNav === 'libre'"
      :zoneData="zoneData"
      :selectedZone="selectedZone"
      @update="pickZone"
      @click="selectZone"
      @change="changeNav('guide')"
    />
    <NavGuide
      v-else-if="typeNav === 'guide'"
      :zoneData="zoneData"
      :selectedZone="selectedZone"
      :menu.sync="menuToggle"
      @click="pickZone"
      @change="changeNav('libre')"
    />
    <NavIntro v-else @typeNav="changeNav" />
    <Cta :cta="cta" />
    <MessagePopup :wait="menuToggle" />
    <CampusFooter />
  </div>
</template>

<script>
import CampusFooter from "../components/CampusFooter.vue";
import CampusHeader from "../components/CampusHeader.vue";
import Cta from "../components/Cta.vue";
import NavLibre from "../components/NavLibre.vue";
import NavGuide from "../components/NavGuide.vue";
import MessagePopup from "../components/MessagePopup.vue";
import NavIntro from "../components/NavIntro.vue";

export default {
  name: "Jpo",
  components: {
    CampusFooter,
    CampusHeader,
    Cta,
    NavLibre,
    NavGuide,
    MessagePopup,
    NavIntro,
  },

  data() {
    return {
      typeNav: null,
      zone: null,
      selectedZone: null,
      menuToggle: false,
    };
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return 'esiea';
    },
    campus() {
      return this.db[this.slug];
    },
    zoneData() {
      let i = this.campus.zone.findIndex((e) => e.code === this.zone);

      if (!this.campus.zone[i]) return null;

      let salles = this.campus.zone[i].salles.map((x) => x);

      return Object.assign({}, this.campus.zone[i], { salles: salles });
    },
    cta() {
      if (this.slug === "esiea") {
        return {
          contact: "https://bit.ly/3ga4LUi",
          doc: "https://bit.ly/3lHui8F",
          help: "https://tawk.to/chat/5644af324e95c217117286dc/default",
        };
      } else if (this.slug === "intech") {
        return {
          contact: "https://bit.ly/2L3AfzX",
          doc: "https://bit.ly/3opg2Dk",
          help: "https://tawk.to/chat/5644af324e95c217117286dc/default",
        };
      }
      return null;
    },
  },
  watch: {
    campus(val) {
      if (val && this.typeNav == null) {
        this.changeNav("guide");
      }
    },
  },
  beforeMount() {
    if (this.slug === "esiea") {
      document.title = "Visite ESIEA - " + process.env.VUE_APP_TITLE;
    } else if (this.slug === "intech") {
      document.title = "Visite INTECH - " + process.env.VUE_APP_TITLE;
    }

    this.$gtag.event("campus_selected", {
      event_category: "engagement",
      event_label: this.slug,
    });
  },

  methods: {
    pickZone(zone) {
      this.zone = zone ?? this.selectedZone;
      if ("ontouchstart" in window) this.selectedZone = zone;
    },
    selectZone(zone) {
      this.selectedZone = zone;
    },
    changeNav(typeNav) {
      if (typeNav !== "guide" && typeNav !== "libre") {
        return;
      }

      this.typeNav = typeNav;
      this.menuToggle = false;
      this.zone = this.campus.zone[0].code;
    },
    back() {
      if (!this.typeNav) this.$router.replace("/");
      else this.typeNav = null;
    },
  },
};
</script>

<style lang="scss">
.welcome {
  font-size: 1.2em;
  line-height: 1.8;
  max-width: 35rem;
  margin: 0 auto;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 609.22 531.45">
    <g id="fond">
      <polygon
        class="cls-1"
        points="329.15 167.74 124.47 402.01 509.17 453.8 373.54 119.65 329.15 167.74"
      />
      <path
        class="cls-2"
        d="M328.64,166.67,323.16,173,308.25,190l-21.94,25.12L259.42,245.9,230,279.61l-29.74,34-27.72,31.74-23.34,26.71L132.3,391.4c-2.67,3.05-5.37,6.07-8,9.15-.11.14-.23.27-.34.4-.65.74-.56,2.34.66,2.5l10.31,1.39,28,3.78,41.33,5.56,50.4,6.79,55.4,7.45L366,436l52.13,7,43.91,5.91,31.58,4.25,15,2,.64.09c1,.13,2.33-.64,1.84-1.84l-3.63-8.95-9.88-24.36-14.56-35.87-17.83-43.91L445.73,292.2,426.1,243.82l-18.38-45.29-15.53-38.25-11.13-27.42q-2.65-6.53-5.3-13.05l-.23-.56a1.51,1.51,0,0,0-2.5-.66l-38.95,42.19-5.44,5.89c-1.31,1.42.81,3.55,2.12,2.13l38.94-42.2,5.45-5.89-2.51-.66,3.63,9,9.89,24.35,14.56,35.87,17.82,43.92,19.53,48.1,19.64,48.39,18.38,45.28,15.53,38.26,11.13,27.42,3.49,8.59c.59,1.46,1.07,3.06,1.8,4.45a4.41,4.41,0,0,1,.23.56l1.85-1.84L499.81,451l-28.06-3.78-41.33-5.56L380,434.84l-55.39-7.46-55.93-7.53-52.13-7-43.9-5.91-31.59-4.25-9.87-1.33c-1.69-.23-3.44-.62-5.15-.69-.21,0-.43-.06-.64-.09l.66,2.51,5.48-6.27,14.91-17.07,21.94-25.11,26.88-30.77,29.46-33.72,29.73-34,27.73-31.73,23.34-26.72,16.87-19.3c2.66-3.06,5.36-6.08,8-9.16l.35-.39C332,167.35,329.91,165.22,328.64,166.67Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-1"
        points="220.78 290.59 373.54 119.65 457.86 324.25 220.78 290.59"
      />
      <path
        class="cls-2"
        d="M222.39,291.65l4.09-4.58,11.14-12.46L254,256.25l20.09-22.47,22-24.62,22.12-24.75,20.7-23.17,17.43-19.51,12.58-14.08c2-2.19,4.16-4.31,5.95-6.65a3.58,3.58,0,0,1,.26-.29l-2.51-.66,2.27,5.49L381,140.43l9.07,22,11.05,26.83,12.12,29.39,12.27,29.78L437,276.1l9.64,23.38,7,16.87c1.08,2.63,2,5.43,3.28,8,.06.11.09.23.14.34l1.85-1.85-6.36-.9-17.3-2.46-25.47-3.61-31.06-4.41-34.15-4.85L310,301.68l-32-4.55-27.07-3.84-19.54-2.78-9.25-1.31-.39-.06a1.57,1.57,0,0,0-1.85,1.05,1.52,1.52,0,0,0,1.05,1.85l6.36.9,17.29,2.45L270.05,299l31.07,4.41,34.14,4.85,34.47,4.89,32,4.55,27.08,3.84,19.54,2.78,9.25,1.31.39.06c1,.13,2.33-.66,1.84-1.85l-2.26-5.5-6.14-14.88-9.06-22-11.06-26.83-12.11-29.39-12.28-29.79-11.4-27.66-9.64-23.38L379,127.54q-1.63-4-3.28-8c0-.11-.09-.23-.14-.34a1.51,1.51,0,0,0-2.5-.66l-4.1,4.58-11.14,12.46L341.39,154l-20.08,22.47-22,24.62-22.12,24.75L256.49,249l-17.43,19.5-12.59,14.09c-2,2.21-4,4.41-5.94,6.65l-.26.29C219,291,221.1,293.09,222.39,291.65Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-1"
        points="3.11 447.98 125.47 402.01 317.82 427.9 305.74 488.71 3.11 447.98"
      />
      <path
        class="cls-2"
        d="M4.06,449.42l18.08-6.79,40.45-15.2,41.58-15.62,14.73-5.53,4.73-1.78a18.15,18.15,0,0,0,2.49-.93c.73-.42-1-.24-.22-.08.61.13,1.26.17,1.89.26l8.75,1.17,32.52,4.38,44.33,6,46.12,6.21,37.89,5.1,19.67,2.65.9.12-1.05-1.85-4.08,20.55-6.51,32.76-1.49,7.5,1.85-1-8.08-1.09-22.07-3L244,478.83l-39.84-5.36-43.49-5.86-43.89-5.91-40.92-5.51-34.59-4.65-24.88-3.35L4.56,446.6l-.5-.07c-1.89-.26-2.71,2.63-.8,2.89l8.07,1.09,22.08,3L66,457.86l39.84,5.36,43.48,5.86L193.16,475l40.93,5.5,34.58,4.66,24.89,3.35,11.83,1.59.5.07a1.54,1.54,0,0,0,1.85-1.05l4.08-20.55,6.5-32.75,1.49-7.51a1.51,1.51,0,0,0-1-1.85l-13.85-1.86L270.46,420l-45.22-6.09-45.79-6.16-36.15-4.87c-5.55-.74-11.11-1.65-16.68-2.24-1.33-.14-2.55.55-3.79,1l-11.36,4.27-38.8,14.57L30,436.5,4.5,446.06l-1.24.47C1.47,447.2,2.24,450.1,4.06,449.42Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-1"
        points="607.37 529.45 509.17 453.8 316.82 427.9 304.74 488.71 607.37 529.45"
      />
      <path
        class="cls-2"
        d="M608.68,528.16,593.29,516.3l-33.83-26.06-33.78-26-11.08-8.54-3.1-2.39a5.12,5.12,0,0,0-1.38-.94,21.05,21.05,0,0,0-3.55-.48l-9.74-1.31-33.37-4.49-44-5.92-45.11-6.07-36.78-5-19-2.55-.85-.12a1.54,1.54,0,0,0-1.85,1.05L311.85,448l-6.5,32.73-1.51,7.58c-.14.72.2,1.74,1.05,1.85l8.11,1.09,22.07,3,32.51,4.38,39.65,5.34,43.58,5.86,44,5.93,41,5.52,34.54,4.65,24.85,3.34L607,530.83l.5.07c1.89.25,2.71-2.64.8-2.89l-8.11-1.1-22.07-3-32.51-4.37L506,514.23l-43.58-5.87-44-5.92-41-5.52-34.54-4.65L318,488.93c-3.9-.53-7.89-1.37-11.82-1.59-.17,0-.34,0-.5-.07l1.05,1.84,4.07-20.5,6.5-32.73,1.5-7.58L317,429.35l13.21,1.78,33.31,4.48,44,5.92,45,6.05,36.87,5,19.1,2.57a3.18,3.18,0,0,0,.69.09c.27,0,.36.23-.13-.12.39.28.76.58,1.14.87l8,6.19,31,23.88,35.51,27.36,21.48,16.55,1,.81C608.69,531.93,610.19,529.32,608.68,528.16Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-1"
        points="314.13 184.92 262.95 149.42 2.11 447.98 124.47 402.01 242.78 266.59 314.13 184.92"
      />
      <path
        class="cls-2"
        d="M315.44,183.62l-17.28-12L270.6,152.52l-6.34-4.4a1.51,1.51,0,0,0-1.82.24l-7,8-19,21.74-28.16,32.23L174,249.54l-37.51,42.93L98.68,335.79,63.4,376.17,33.59,410.3,12.16,434.82,2,446.42l-.43.49c-.9,1-.06,3.08,1.46,2.51l28.49-10.7,55.39-20.81,37.19-14a6.31,6.31,0,0,0,2-.93,17,17,0,0,0,1.52-1.75l8.71-10,32.95-37.71,39.63-45.36,29.54-33.82,10.42-11.93,34.44-39.42,30.71-35.14,1.7-1.94c1.26-1.45-.85-3.58-2.13-2.12L290.81,210,253,253.21,240.13,268l-24.37,27.89-38.69,44.29-36.18,41.4L129,395.14l-3.5,4c-.31.36-1.45,1.27-1.53,1.75,0,.08,1-.4.39-.24a9.41,9.41,0,0,0-1.19.45l-5.38,2-19.46,7.31L43.21,431.14,4.29,445.77l-2,.76L3.72,449l7-8,19-21.74,28.16-32.22,34.25-39.21,37.5-42.92,37.85-43.33,35.29-40.38,29.81-34.12L254,162.57,264.13,151l.43-.49-1.82.23,17.29,12,27.56,19.12,6.34,4.39C315.52,187.32,317,184.72,315.44,183.62Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-1"
        points="509.17 453.8 402.41 190.78 463.04 173.87 607.37 529.45 509.17 453.8"
      />
      <path
        class="cls-2"
        d="M511.16,453.4l-2.85-7-7.77-19.15L489,398.89l-14-34.55L459.66,326.5l-15.44-38-14.46-35.63-12.22-30.11-8.79-21.65L406,194.35a32.75,32.75,0,0,0-1.42-3.52,4.07,4.07,0,0,1-.18-.44l-1.05,1.84,20.39-5.69,32.73-9.13,7.51-2.09-1.85-1,3.86,9.5,10.49,25.85L492,247.86l19,46.82,20.75,51.12,20.94,51.6,19.53,48.12,16.5,40.65,11.87,29.24c1.86,4.57,3.44,9.41,5.62,13.85a6.07,6.07,0,0,1,.24.59l2.21-1.69-9.75-7.51-23.42-18-28.35-21.83-24.53-18.91-8.1-6.24a42.94,42.94,0,0,0-3.88-3l-.17-.14c-1.53-1.18-3,1.43-1.52,2.59l9.75,7.51,23.42,18,28.34,21.84L595,521.38l8.1,6.25a44.25,44.25,0,0,0,3.87,3,1.24,1.24,0,0,1,.18.14,1.52,1.52,0,0,0,2.21-1.7l-3.86-9.49L595,493.7,579.5,455.46l-19-46.82-20.75-51.12-20.94-51.6-19.53-48.11-16.51-40.66L470.9,187.91c-1.88-4.61-3.74-9.23-5.63-13.85l-.24-.59a1.53,1.53,0,0,0-1.84-1.05l-20.39,5.69-32.73,9.13-7.51,2.1a1.52,1.52,0,0,0-1.05,1.84l2.86,7,7.77,19.15,11.5,28.32,14,34.55L453,318.08l15.44,38,14.46,35.63,12.22,30.1,8.79,21.66q2.08,5.13,4.16,10.25l.18.44C509,456,511.89,455.19,511.16,453.4Z"
        transform="translate(-0.55)"
      />
      <polygon
        class="cls-3"
        points="314.13 184.92 263.95 149.42 393.98 3.73 463.04 173.87 402.41 190.78 373.54 119.65 314.13 184.92"
      />
      <polygon
        class="cls-1"
        points="457.86 324.25 509.17 452.8 124.47 401.01 220.78 290.59 457.86 324.25"
      />
      <path
        class="cls-2"
        d="M457,324.65l5.11,12.8,12.27,30.74,14.77,37,12.83,32.15,4.22,10.56c.61,1.54,1.07,3.71,2,5.07a1,1,0,0,1,.09.22l1.85-1.84L499.81,450l-28.06-3.78-41.33-5.56L380,433.84l-55.39-7.46-55.93-7.53-52.13-7-43.9-5.91-31.59-4.25-9.87-1.33c-1.69-.23-3.44-.62-5.15-.69-.21,0-.43-.06-.64-.09l.66,2.51,9.55-10.95,23-26.31,27.91-32,24-27.55,7.95-9.12,2.44-2.79a9.27,9.27,0,0,0,1.31-1.5,1.63,1.63,0,0,1,.17-.2l-1.46.39,23.74,3.37,56.58,8,68.38,9.71,59.13,8.39q14.43,2.06,28.86,4.1l.39.06c1.89.26,2.7-2.63.8-2.9l-15.87-2.25-40.15-5.7-53.2-7.55-55.07-7.82L248.92,293l-25.26-3.58c-1.39-.2-2.68-.62-3.74.5-2.28,2.44-4.4,5.06-6.6,7.58l-29.94,34.32-36.16,41.45-22.17,25.42L124,400c-.65.74-.56,2.34.66,2.5l10.31,1.39,28,3.78,41.33,5.56,50.4,6.79,55.4,7.45L366,435l52.13,7,43.91,5.91,31.58,4.25,15,2,.64.09c1,.13,2.32-.64,1.84-1.84l-5.11-12.81-12.27-30.74-14.76-37L466.18,339.7c-2.07-5.19-4-10.51-6.24-15.63l-.09-.22C459.15,322.08,456.24,322.85,457,324.65Z"
        transform="translate(-0.55)"
      />
    </g>
    <g :class="{ active: activeZone == 'prepa' }" @mouseover="$emit('update', 'prepa', $event)" @click="$emit('click', 'prepa', $event)" @touchstart="$emit('click', 'prepa', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-4"
        points="221.27 291.47 374.03 120.53 458.35 325.13 221.27 291.47"
      />
      <path
        class="cls-2"
        d="M222.88,292.53,227,288l11.14-12.46,16.4-18.36,20.09-22.47,22-24.62,22.12-24.75,20.7-23.17,17.43-19.51,12.58-14.08c2-2.19,4.16-4.31,5.95-6.65a3.58,3.58,0,0,1,.26-.29l-2.51-.66,2.27,5.49,6.13,14.89,9.07,22,11,26.83,12.12,29.39L426,249.31,437.44,277l9.64,23.38L454,317.23c1.08,2.63,2,5.43,3.28,8a3.45,3.45,0,0,1,.14.34l1.85-1.85-6.36-.9-17.29-2.46-25.48-3.61-31.06-4.41L345,307.45l-34.46-4.89-32-4.55-27.07-3.84-19.54-2.78-9.25-1.31-.39-.06a1.57,1.57,0,0,0-1.85,1.05,1.52,1.52,0,0,0,1,1.85l6.36.9,17.29,2.45,25.47,3.62,31.07,4.41,34.14,4.85L370.22,314l32,4.55,27.07,3.84,19.54,2.78,9.25,1.31.39.05c1,.14,2.34-.65,1.84-1.84l-2.26-5.5-6.14-14.88-9.06-22-11.06-26.83-12.11-29.39-12.28-29.79L396,168.68,386.4,145.3l-7-16.88q-1.63-4-3.28-7.95c0-.11-.09-.23-.14-.34a1.51,1.51,0,0,0-2.5-.66l-4.1,4.58-11.14,12.46-16.4,18.36L321.8,177.34,299.8,202l-22.12,24.75L257,249.88l-17.43,19.5L227,283.47c-2,2.21-4,4.41-5.94,6.65l-.26.29C219.48,291.84,221.59,294,222.88,292.53Z"
        transform="translate(-0.55)"
      />
      <text class="cls-5" transform="translate(326.38 228.3) rotate(8.16)">
        Cycle
      </text>
      <text class="cls-5" transform="translate(286.38 258.3) rotate(8.16)">
        Préparatoire
      </text>
    </g>
    <g :class="{ active: activeZone == 'admission' }" @mouseover="$emit('update', 'admission', $event)" @click="$emit('click', 'admission', $event)" @touchstart="$emit('click', 'admission', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-6"
        points="607.74 529.86 509.53 454.2 317.18 428.31 305.1 489.12 607.74 529.86"
      />
      <path
        class="cls-2"
        d="M609,528.56l-15.39-11.85-33.83-26.07-33.77-26L515,456.09l-3.11-2.4a5,5,0,0,0-1.38-.93,20.94,20.94,0,0,0-3.54-.48L497.19,451l-33.37-4.5-44-5.91-45.11-6.08-36.78-5L319,427l-.86-.12a1.53,1.53,0,0,0-1.84,1.05l-4.08,20.5-6.5,32.73-1.5,7.58c-.14.71.2,1.73,1,1.85l8.11,1.09,22.07,3L368,499l39.65,5.33,43.57,5.87,44,5.92,41,5.52,34.53,4.65,24.85,3.35,11.82,1.59.51.07c1.88.25,2.7-2.64.79-2.9l-8.11-1.09-22.07-3L546,520l-39.65-5.33-43.58-5.87-44-5.92-41-5.52-34.54-4.65-24.84-3.35c-3.9-.52-7.89-1.37-11.82-1.59a4.46,4.46,0,0,1-.51-.07l1.05,1.85,4.07-20.5,6.5-32.73,1.51-7.58-1.85,1,13.21,1.78L363.86,436l43.95,5.91,45,6.06,36.86,5,19.1,2.57a4.1,4.1,0,0,0,.69.1c.28,0,.37.23-.12-.12.38.27.75.58,1.13.87l8,6.19,31,23.88L585,513.8l21.48,16.54,1,.81C609.06,532.33,610.55,529.73,609,528.56Z"
        transform="translate(-0.55)"
      />
      <text class="cls-5" transform="translate(335.76 470.08) rotate(7.53)">
        Rejoindre l'ESIEA
      </text>
    </g>
    <g :class="{ active: activeZone == 'pedagogie' }" @mouseover="$emit('update', 'pedagogie', $event)" @click="$emit('click', 'pedagogie', $event)" @touchstart="$emit('click', 'pedagogie', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-7"
        points="508.94 453.77 402.18 190.76 462.81 173.84 607.14 529.43 508.94 453.77"
      />
      <path
        class="cls-2"
        d="M510.93,453.37l-2.85-7-7.78-19.14-11.49-28.32-14-34.55-15.36-37.84-15.43-38-14.46-35.62-12.22-30.11-8.79-21.65-2.74-6.74a31.63,31.63,0,0,0-1.43-3.52c-.07-.14-.12-.3-.18-.44l-1,1.85,20.39-5.69,32.73-9.14,7.5-2.09-1.84-1,3.85,9.5,10.5,25.85,15.52,38.24,19,46.82,20.75,51.13,21,51.6L572,445.49l16.5,40.66,11.87,29.23c1.86,4.58,3.44,9.42,5.62,13.85.09.19.16.4.24.6l2.2-1.7-9.74-7.51-23.42-18-28.35-21.84-24.53-18.9-8.11-6.24a42.91,42.91,0,0,0-3.87-3l-.18-.14c-1.53-1.17-3,1.43-1.51,2.59l9.75,7.52,23.42,18,28.34,21.84,24.53,18.9,8.11,6.24a42.38,42.38,0,0,0,3.87,3l.18.14a1.51,1.51,0,0,0,2.2-1.69l-3.85-9.5-10.5-25.85-15.52-38.24-19-46.82L539.51,357.5l-21-51.61L499,257.78l-16.5-40.66-11.87-29.23Q467.86,181,465,174l-.24-.59a1.53,1.53,0,0,0-1.84-1l-20.4,5.69-32.72,9.13-7.51,2.09a1.51,1.51,0,0,0-1.05,1.85l2.86,7,7.77,19.15,11.49,28.32,14,34.55,15.36,37.84,15.44,38,14.46,35.62,12.22,30.11,8.79,21.65c1.39,3.42,2.76,6.84,4.16,10.26l.18.44C508.76,455.93,511.66,455.16,510.93,453.37Z"
        transform="translate(-0.55)"
      />
      <text
        class="cls-5"
        transform="matrix(0.37, 0.93, -0.93, 0.37, 465.77, 277.89)"
      >
        Après l'ESIEA
      </text>
    </g>
    <g :class="{ active: activeZone == 'vie' }" @mouseover="$emit('update', 'vie', $event)" @click="$emit('click', 'vie', $event)" @touchstart="$emit('click', 'vie', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-6"
        points="314.15 185.24 263.97 149.73 393.99 4.04 463.05 174.19 402.43 191.1 373.55 119.96 314.15 185.24"
      />
      <path
        class="cls-2"
        d="M315.46,183.94l-17-12-27-19.12-6.2-4.38.31,2.35,3.48-3.9,9.47-10.61,13.94-15.62,17.08-19.13,18.71-21,18.89-21.17,17.61-19.73,14.83-16.61L390.3,11c1.67-1.87,3.58-3.68,5.08-5.69.07-.09.15-.17.22-.25l-2.5-.66L394.94,9l5,12.36,7.46,18.38,9.06,22.33L426.4,86.5l10,24.68,9.34,23,7.9,19.45,5.68,14c.89,2.18,1.61,4.56,2.7,6.64,0,.09.08.2.12.29l1-1.84-20.49,5.72-32.66,9.11-7.48,2.08,1.84,1-9.76-24.07L379.1,128.31l-3.55-8.75a1.52,1.52,0,0,0-2.51-.66l-5.91,6.5L352.94,141l-17.17,18.86L321,176.1c-2.41,2.65-4.91,5.24-7.24,8a1.14,1.14,0,0,1-.11.11c-1.3,1.43.82,3.56,2.12,2.13l5.91-6.5,14.2-15.6L353,145.34l14.77-16.24,4.93-5.41a25.41,25.41,0,0,0,2.32-2.55l.1-.12-2.5-.66,9.77,24.07L398,182.75l3.55,8.75a1.54,1.54,0,0,0,1.85,1.05l20.49-5.72,32.65-9.11,7.48-2.09a1.5,1.5,0,0,0,1.05-1.84l-1.84-4.55-5-12.36-7.46-18.38-9.06-22.32-9.93-24.45L421.72,67l-9.34-23-7.89-19.45-5.69-14-2.69-6.65L396,3.64A1.52,1.52,0,0,0,393.48,3L390,6.88,380.53,17.5,366.59,33.11,349.51,52.25l-18.71,21L311.91,94.38,294.3,114.12l-14.83,16.61-10.71,12c-1.7,1.89-3.42,3.77-5.08,5.69l-.22.24a1.53,1.53,0,0,0,.3,2.36l17,12,27,19.12,6.19,4.38C315.52,187.65,317,185.05,315.46,183.94Z"
        transform="translate(-0.55)"
      />
      <text class="cls-5" transform="translate(373.5 48.08) rotate(8.16)">
        <tspan x="-5.65" y="26">Vie</tspan>
        <tspan x="-38.26" y="56">Etudiante</tspan>
      </text>
    </g>
    <g :class="{ active: activeZone == 'pe' }" @mouseover="$emit('update', 'pe', $event)" @click="$emit('click', 'pe', $event)" @touchstart="$emit('click', 'pe', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-4"
        points="1.49 448.4 123.85 402.43 316.2 428.33 304.12 489.14 1.49 448.4"
      />
      <path
        class="cls-2"
        d="M2.43,449.85l18.08-6.8L61,427.86l41.59-15.62,14.73-5.54,4.73-1.77a17.59,17.59,0,0,0,2.48-.94c.73-.42-1-.24-.21-.07.61.13,1.26.17,1.89.25l8.74,1.18,32.53,4.38,44.33,6,46.11,6.2,37.9,5.1,19.67,2.65.9.12-1.05-1.84-4.08,20.55-6.51,32.75-1.49,7.51,1.85-1.05L297,486.61l-22.07-3-32.54-4.38-39.85-5.36L159.05,468l-43.89-5.91-40.92-5.51L39.65,452l-24.89-3.35L2.94,447,2.43,447c-1.88-.25-2.7,2.64-.79,2.9l8.07,1.08,22.08,3,32.54,4.39,39.84,5.36,43.48,5.85,43.89,5.91,40.92,5.51,34.59,4.66,24.89,3.35,11.83,1.59.5.07a1.53,1.53,0,0,0,1.84-1L310.2,469l6.5-32.76,1.49-7.5c.14-.72-.2-1.74-1.05-1.85L303.3,425l-34.46-4.64-45.22-6.09-45.79-6.16-36.15-4.87c-5.55-.75-11.11-1.66-16.68-2.25-1.34-.14-2.55.56-3.79,1l-11.36,4.27L71,420.88l-42.7,16L2.88,446.49,1.64,447C-.15,447.63.62,450.53,2.43,449.85Z"
        transform="translate(-0.55)"
      />
      <text class="cls-5" transform="translate(106.54 440.05) rotate(7.53)">
        Projets Etudiants
      </text>
    </g>
    <g :class="{ active: activeZone == 'ecole' }" @mouseover="$emit('update', 'ecole', $event)" @click="$emit('click', 'ecole', $event)" @touchstart="$emit('click', 'ecole', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-7"
        points="314.53 185 263.35 149.5 2.51 448.06 124.87 402.09 243.18 266.67 314.53 185"
      />
      <path
        class="cls-2"
        d="M315.84,183.71l-17.28-12L271,152.6l-6.34-4.39a1.5,1.5,0,0,0-1.82.23l-7,8-19,21.73-28.15,32.23-34.25,39.2-37.51,42.93L99.08,335.87,63.8,376.26,34,410.38,12.56,434.9,2.42,446.51,2,447c-.89,1-.05,3.07,1.46,2.5l28.49-10.7L87.34,418l37.19-14a6.35,6.35,0,0,0,2-.92,18.08,18.08,0,0,0,1.53-1.75l8.7-10,32.95-37.72,39.64-45.36,29.54-33.81,10.42-11.93,34.44-39.42L314.44,188l1.7-1.95c1.27-1.45-.84-3.58-2.12-2.12L291.2,210.06l-37.76,43.23-12.91,14.77L216.16,296l-38.69,44.29-36.18,41.41-11.86,13.57-3.5,4c-.31.35-1.45,1.26-1.53,1.75,0,.07,1-.41.38-.25a9.88,9.88,0,0,0-1.18.45l-5.38,2-19.46,7.31L43.61,431.23,4.69,445.85l-2,.76,1.46,2.51,7-8,19-21.74,28.16-32.22,34.25-39.2L130,305l37.85-43.32,35.29-40.39L233,187.18l21.42-24.53,10.14-11.6.43-.49-1.82.24,17.29,12L308,181.9l6.35,4.4C315.92,187.4,317.42,184.8,315.84,183.71Z"
        transform="translate(-0.55)"
      />
      <text
        class="cls-5"
        transform="matrix(0.65, -0.76, 0.76, 0.65, 167.19, 311.99)"
      >
        Ecole
      </text>
    </g>
    <g :class="{ active: activeZone == 'inge' }" @mouseover="$emit('update', 'inge', $event)" @click="$emit('click', 'inge', $event)" @touchstart="$emit('click', 'inge', $event)" @mouseleave="$emit('update', null, $event)">
      <polygon
        class="cls-8"
        points="457.83 325.33 509.14 453.88 124.44 402.09 220.75 291.67 457.83 325.33"
      />
      <path
        class="cls-2"
        d="M456.93,325.73l5.12,12.8,12.26,30.74,14.77,37,12.84,32.15L506.13,449c.62,1.54,1.07,3.7,2,5.07a1.45,1.45,0,0,1,.09.22l1.84-1.85-10.31-1.38-28-3.78L430.4,441.7,380,434.92l-55.4-7.46-55.92-7.52-52.13-7L172.64,407l-31.58-4.26-9.87-1.32c-1.7-.23-3.45-.62-5.16-.7-.21,0-.43-.06-.64-.08l.66,2.5,9.55-10.95,23-26.31,27.91-32,24-27.54,7.95-9.12,2.44-2.8a8.44,8.44,0,0,0,1.31-1.5,1,1,0,0,1,.17-.19l-1.45.38,23.73,3.37,56.59,8,68.38,9.71,59.12,8.4c9.62,1.36,19.24,2.75,28.86,4.09l.39.06c1.89.27,2.71-2.62.8-2.89l-15.87-2.26-40.14-5.7-53.21-7.55-55.07-7.82-45.6-6.47-25.26-3.59c-1.38-.19-2.68-.61-3.73.51-2.29,2.44-4.41,5.05-6.6,7.57L183.36,332.9,147.2,374.36,125,399.77l-1.1,1.26c-.65.74-.55,2.35.66,2.51l10.31,1.39L163,408.7l41.33,5.57,50.4,6.78,55.39,7.46L366,436l52.14,7L462,449l31.59,4.26,15,2,.64.09c1,.13,2.33-.65,1.85-1.85L506,440.68l-12.27-30.74-14.77-37-12.83-32.15c-2.08-5.2-4-10.52-6.24-15.64a1.45,1.45,0,0,1-.09-.22C459.12,323.16,456.22,323.94,456.93,325.73Z"
        transform="translate(-0.55)"
      />
      <text class="cls-5" transform="translate(246.23 367.86) rotate(8.16)">
        Cycle Ingénieur
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    activeZone: String
  }
};
</script>

<style scoped>
.cls-1,
.cls-3 {
  fill: #b4b4b4;
}
.cls-2,
.cls-5 {
  fill: #fff;
}
.cls-3 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
.cls-4 {
  fill: #36a8e0;
}
.cls-5 {
  font-size: 20px;
  font-family: Poppins-Bold, Poppins, sans-serif;
  font-weight: 700;
}
.cls-6 {
  fill: #f59e00;
}
.cls-7 {
  fill: #4a4a49;
}
.cls-8 {
  fill: #151d34;
}

svg > g:not(:first-child) > *:not(text) {
  opacity: 0;
}

svg > g.active > * {
  opacity: 1 !important;
}

svg > g:not(#fond):hover > * {
  opacity: 1;
  cursor: pointer;
}
</style>
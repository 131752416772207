<template>
  <div id="app">
    <b-container fluid class="mb-3">
      <b-row>
        <b-col cols="12">
          <img
            src="../assets/logo-groupe-esiea.svg"
            alt="Groupe ESIEA"
            class="d-block mx-auto"
          />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="text-center">
      <b-row
        class="h-100 align-content-center"
        align-v="center"
        align-h="center"
        v-if="timer > 0"
      >
        <b-col cols="12" class="px-3">
          <h1 class="font-weight-bold">
            Oui, nous avons tellement hâte de vous rencontrer que nous comptons
            les jours<br class="d-none d-xl-inline-block" />
            pour vous retrouver sur
            <span class="text-primary"
              >nos journées portes ouvertes du {{ dateText }} !</span
            >
          </h1>
        </b-col>
        <b-col cols="12" lg="10" xl="8" class="mt-5">
          <Countdown :timer="timer" />
        </b-col>
        <b-col cols="12" lg="10" xl="8" class="mt-5">
          <h2 class="text-uppercase font-weight-bold mb-3">
            Pas encore inscrit.e. ?
          </h2>
          <b-btn
            variant="outline-light"
            size="lg"
            href="https://www.esiea.fr/rencontrer/"
            target="_blank"
            class="mx-2 my-2"
            >Inscris-toi dès maintenant</b-btn
          >
          <b-btn
            variant="outline-light"
            size="lg"
            href="mailto:admissions@esiea.fr"
            target="_blank"
            class="mx-2 my-2"
          >
            <font-awesome-icon
              :icon="['far', 'envelope']"
              fixed-width
              class="mr-1"
            />Écris-nous à admissions@esiea.fr
          </b-btn>
        </b-col>
        <b-col cols="12">
          <h3 class="font-weight-bold mt-5 mb-0">
            retrouvez-nous sur les réseaux sociaux
          </h3>
        </b-col>
        <b-col cols="12" sm="6" xl="3" class="">
          <img
            src="../assets/logo-esiea.svg"
            class="d-block mx-auto"
            alt="ESIEA"
          />
          <a href="https://bit.ly/369ZDwk" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'facebook-f']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/37aARLN" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'instagram']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/33hYUY4" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'twitter']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3l8scOL" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3pXTYB8" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'youtube']"
              size="2x"
              fixed-width
          /></a>
        </b-col>
        <b-col cols="12" sm="6" xl="3" class="mt-4 mt-sm-0">
          <img
            src="../assets/logo-intech.svg"
            class="d-block mx-auto"
            alt="INTECH"
          />
          <a href="https://bit.ly/369JqqT" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'facebook-f']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3o5Cl0J" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'instagram']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/2HJdfFk" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'twitter']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3lblrMf" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/2JhH28g" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'youtube']"
              size="2x"
              fixed-width
          /></a>
        </b-col>
      </b-row>
      <b-row
        class="h-100 align-content-center"
        align-v="center"
        align-h="center"
        v-else
      >
        <b-col cols="12" class="px-3">
          <h1 class="font-weight-bold">
            Notre Journée Portes Ouvertes en ligne est finie, mais il n’est pas trop tard
            pour vous renseigner sur nos écoles
          </h1>
        </b-col>
        <b-col cols="12" sm="6" xl="5" class="mt-5">
          <img
            src="../assets/logo-esiea.svg"
            class="d-block mx-auto"
            alt="ESIEA"
          />
          <div class="mb-5">
            <b-btn
              variant="outline-light"
              size="lg"
              href="https://bit.ly/3ga4LUi"
              target="_blank"
              class="mx-2"
              >Nous contacter</b-btn
            >
            <b-btn
              variant="outline-light"
              size="lg"
              href="https://bit.ly/39JTxVC"
              target="_blank"
              class="mx-2"
              >En savoir plus</b-btn
            >
          </div>
          <a href="https://bit.ly/369ZDwk" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'facebook-f']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/37aARLN" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'instagram']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/33hYUY4" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'twitter']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3l8scOL" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3pXTYB8" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'youtube']"
              size="2x"
              fixed-width
          /></a>
        </b-col>
        <b-col cols="12" sm="6" xl="5" class="mt-5">
          <img
            src="../assets/logo-intech.svg"
            class="d-block mx-auto"
            alt="INTECH"
          />
          <div class="mb-5">
            <b-btn
              variant="outline-light"
              size="lg"
              href="https://bit.ly/2L3AfzX "
              target="_blank"
              class="mx-2"
              >Nous contacter</b-btn
            >
            <b-btn
              variant="outline-light"
              size="lg"
              href="https://bit.ly/3mJyu9c"
              target="_blank"
              class="mx-2"
              >En savoir plus</b-btn
            >
          </div>
          <a href="https://bit.ly/369JqqT" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'facebook-f']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3o5Cl0J" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'instagram']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/2HJdfFk" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'twitter']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/3lblrMf" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              size="2x"
              fixed-width
          /></a>
          <a href="https://bit.ly/2JhH28g" class="text-light" target="_blank"
            ><font-awesome-icon
              :icon="['fab', 'youtube']"
              size="2x"
              fixed-width
          /></a>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="text-center py-3 mt-4" id="footer">
      © Tous droits réservés - ESIEA Groupe -
      <a href="https://www.esiea.fr/accueil/mentions-legales/" target="_blank"
        >Mentions légales</a
      >
    </b-container>
  </div>
</template>

<script>
import Countdown from "./Countdown.vue";
import moment from "moment-timezone";

export default {
  name: "Waiting",
  components: { Countdown },
  props: {
    timer: { type: Number, required: true },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },
  computed: {
    dateText() {
      return moment(this.startDate).tz("Europe/Paris").locale("fr").format("dddd Do MMMM YYYY [de] H[h]") + " " + moment(this.endDate).tz("Europe/Paris").locale("fr").format("à H[h]");
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_title: 'Compte à rebours',
      page_path: '/' + window.location.hash
    })
  }
};
</script>

<style scoped lang="scss">
img {
  height: 10rem;
}
</style>
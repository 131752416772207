<template>
  <div class="aside-content">
    <b-row no-gutters cols="1" cols-lg="2" cols-xl="3">
      <b-col v-for="salle in salles" :key="salle.code" class="px-1 mb-2">
        <a
          :href="salle.url"
          target="_blank"
          class="text-white d-block h-100"
          @click="link(salle, $event)"
        >
          <b-card
            :bg-variant="salle.cta ? 'dark' : salle.ligthbox ? 'primary' : 'secondary'"
            class="h-100"
            body-class="d-flex flex-column justify-content-between"
          >
            <div class="content pb-2 text-left">
              <h5 class="text-uppercase">{{ salle.nom }}</h5>
              <p class="mb-0">{{ salle.description }}</p>
            </div>
            <div class="picto text-right font-italic" :class="{'text-dark': !salle.cta}">
              <span v-if="salle.ligthbox"
                >Visionner la vidéo<font-awesome-icon
                  :icon="['far', 'play-circle']"
                  fixed-width
                  size="lg"
                  class="ml-2"
              /></span>
              <span v-else-if="!salle.cta"
                >Echanger en direct<font-awesome-icon
                  :icon="['far', 'comments']"
                  fixed-width
                  size="lg"
                  class="ml-2"
              /></span>
              <span v-else
                >Ouvrir<font-awesome-icon
                  :icon="['fas', 'external-link-alt']"
                  fixed-width
                  size="lg"
                  class="ml-2"
              /></span>
            </div>
          </b-card>
        </a>
      </b-col>
      <Lightbox v-model="lightbox" />
    </b-row>
  </div>
</template>

<script>
import Lightbox from "./Lightbox.vue";
export default {
  components: { Lightbox },
  props: {
    salles: Array,
  },

  data() {
    return {
      lightbox: null,
    };
  },

  methods: {
    link(salle, event) {
      this.$gtag.event("click_tile", {
        event_label: salle.code,
        event_category: "engagement"
      });

      if (salle.ligthbox) {
        event.preventDefault();
        this.lightbox = salle.url + "?autoplay=1&enablejsapi=1";
      }
    },
  },
};
</script>

<style lang="scss">
.aside-content {
  a {
    text-decoration: none;
  }
}

.card {
  font-size: 0.95em;
  line-height: 1.2;

  &:hover,
  &:focus {
    background-color: #151d34 !important;

    .picto {
      color: #fff !important;
    }
  }

  .content p {
    font-size: 1.1em;
  }
}
</style>

<template>
  <b-container fluid class="text-center py-3 bg-dark" id="footer">
    <b-row>
      <b-col cols="12" class="text-lg-left my-2 my-lg-0" lg
        ><a href="tel:+33671336635" target="_blank" class="text-white"
          ><font-awesome-icon
            :icon="['fas', 'phone']"
            flip="horizontal"
            fixed-width
            class="ml-2"
          />
          +33 (0)1 82 39 25 00</a
        >
        <a
          href="mailto:admissions@esiea.fr"
          target="_blank"
          class="text-white ml-3"
          ><font-awesome-icon :icon="['far', 'envelope']" fixed-width />
          admissions@esiea.fr</a
        ></b-col
      >
      <b-col cols="12" lg="auto">
        © Tous droits réservés - ESIEA Groupe -
        <a
          href="https://www.esiea.fr/accueil/mentions-legales/"
          target="_blank"
          class="text-white"
          >Mentions légales</a
        >
      </b-col>
      <b-col
        cols="12"
        lg
        class="text-center text-lg-right my-2 my-lg-0"
        v-if="slug === 'intech'"
      >
        <a href="https://bit.ly/369JqqT" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'facebook-f']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/3o5Cl0J" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'instagram']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/2HJdfFk" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'twitter']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/3lblrMf" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'linkedin-in']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/2JhH28g" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'youtube']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
      </b-col>
      <b-col
        cols="12"
        lg
        class="text-center text-lg-right my-2 my-lg-0"
        v-if="slug === 'esiea'"
      >
        <a href="https://bit.ly/369ZDwk" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'facebook-f']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/37aARLN" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'instagram']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/33hYUY4" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'twitter']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/3l8scOL" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'linkedin-in']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://bit.ly/3pXTYB8" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'youtube']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
      </b-col>
    </b-row>
    <Lightbox v-model="lightbox" />
  </b-container>
</template>

<script>
import Lightbox from "./Lightbox.vue";
export default {
  components: { Lightbox },

  data() {
    return {
      lightbox: null,
    };
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return 'esiea';
    },
    campus() {
      return this.db[this.slug];
    },
  },

  methods: {
    link(url, event) {
      event.preventDefault();
      this.lightbox = url + "?autoplay=1";
    },
  },
};
</script>
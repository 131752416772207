<template>
    <div id="cta" v-if="cta" class="mt-auto">
      <a :href="cta.doc" target="_blank">
        <font-awesome-icon :icon="['fas', 'book-open']" size="lg" fixed-width />
        Lire la brochure
      </a>
      <a :href="cta.contact" target="_blank">
        <font-awesome-icon
          :icon="['fas', 'phone-volume']"
          fixed-width
          size="lg"
        />
        être recontacté
      </a>
      <!-- <a :href="cta.help" target="_blank">
        <font-awesome-icon :icon="['fas', 'question']" size="lg" fixed-width />
        Aide
      </a> -->
    </div>
</template>

<script>
export default {
  props: {
    cta: Object,
  },
};
</script>